import type { ConfigurazionePersonalizzataCliente } from "../models/customer/configurazionePersonalizzataCliente";

export const getCustomConfigFieldValue = (
  fieldId: number,
  fields: Array<ConfigurazionePersonalizzataCliente>,
  fieldValue: string,
) => fields.find((c) => c.iD_Campo == fieldId && fieldValue == c.valore)?.nome;

export const canSeeCustomConfigField = (
  fieldId: number,
  fields: Array<ConfigurazionePersonalizzataCliente>,
  fieldValue: string,
) => {
  const field = fields.find(
    (c) => c.iD_Campo === fieldId && fieldValue === c.valore,
  );
  return (field && !field.bloccato) || fieldId === 1;
};

export const isCustomConfigFieldMandatory = (
  fieldId: number,
  fields: Array<ConfigurazionePersonalizzataCliente>,
  fieldValue: string|null,
) => {
  fields.find(
    (c) => c.iD_Campo === fieldId && (!fieldValue || fieldValue === c.valore) && c.obbligatorio,
  ) !== undefined;
}

export const isCustomConfigFieldBlocked = (
  fieldId: number,
  fields: Array<ConfigurazionePersonalizzataCliente>,
  fieldValue: string|null,
) =>
  fields.find(
    (c) => c.iD_Campo === fieldId && (!fieldValue || fieldValue === c.valore) && c.bloccato,
  ) !== undefined;
