import { defineStore } from "pinia";
import { POSITION } from "vue-toastification";
import { NEW_PASSENGER } from "../constants/utils";
import type { PagingSelectionModel } from "../models/api/pagination";
import type { ConfigurazionePersonalizzataCliente } from "../models/customer/configurazionePersonalizzataCliente";
import { PaymentTypes } from "../models/payment/paymentTypes";
import type { ScontoCoupon } from "../models/promo/coupon";
import type { CreditoRequest } from "../models/promo/credit";
import type { TipoServizioT } from "../models/service_type/service_type";
import type { GoogleRoute } from "../models/trip/route";
import {
  TRIP_TO_INSERT_INITIAL_VALUE,
  ValuteListini,
  type PreventivoModel,
  type CustomerTripSummary,
  type Viaggio,
  type ViaggiPasseggeriWithPasseggero,
  type CentroNotifiche,
} from "../models/trip/trip";
import type { Passeggero } from "../models/user/passeggero";
import {
  type SavableItem,
  type AccordionItem,
  getVattedPrice,
  getNetPriceFromGross,
  getTripDiscountValue,
} from "../models/utils";
import type { Mezzi } from "../models/vehicles/mezzi";
import DriverApi from "../services/DriverApi";
import PassengerApi from "../services/PassengerApi";
import PromoApi from "../services/PromoApi";
import ServiceTypeApi from "../services/ServiceTypeApi";
import TripApi from "../services/TripApi";
import VehicleApi from "../services/VehicleApi";
import { convertPricingCurrencyToRequestedCurrency } from "../utils/convertTripCurrency";
import {
  canSeeCustomConfigField,
  getCustomConfigFieldValue,
  isCustomConfigFieldBlocked,
  isCustomConfigFieldMandatory,
} from "../utils/customConfigFieldUtils";
import { dateToDateTime } from "../utils/dateToDateTime";
import { useCompanyStore } from "./company";
import { useUserStore } from "./user";
import { datadogRum } from "@datadog/browser-rum";
import { toRaw } from "vue";
import type { AllegatiViaggio } from "@/DTO/AllegatiDTO";
import { isValidEmail } from "../utils/validators";
import type { ClientiModel } from "../models/customer/customer";

type TripStoreState = {
  tripToInsert: Viaggio;
  tripApi: TripApi;
  preventivi: PreventivoModel[];
  selectedPricings: PreventivoModel[];
  passengersInCar: (Passeggero & AccordionItem & SavableItem)[];
  passengersApi: PassengerApi;
  baggage: number;
  luggage: number;
  extraAssistance: boolean;
  serviceTypesApi: ServiceTypeApi;
  carDisposal: TipoServizioT[];
  rideType: "work" | "private" | null;
  driverApi: DriverApi;
  vehicleApi: VehicleApi;
  userStore: ReturnType<typeof useUserStore>;
  useInternalCredit: boolean;
  promoApi: PromoApi;
  babySeats: string[];
  nonEndedTrips: CustomerTripSummary[];
  enededTrips: Map<number, CustomerTripSummary[]> | null;
  lastCreatedTrip: {
    tripToInsert: Viaggio | null;
    passengersInCar: (Passeggero & AccordionItem & SavableItem)[];
    rideType: "work" | "private" | null;
    baggage: number;
    luggage: number;
    extraAssistance: boolean;
    babySeats: Array<string>;
  };
  currentPassengerCustomCustomerConfig: ConfigurazionePersonalizzataCliente[];
  companyStore: ReturnType<typeof useCompanyStore>;
  availableVehicles: TipoMezzoExt[];
  rideNotifications: Partial<CentroNotifiche>[];
  referencePassengerId: number | null; //passenger of the ride in creation
};

export const useTripStore = defineStore("trip", {
  state: (): TripStoreState => ({
    tripToInsert: null as any,
    tripApi: new TripApi(),
    selectedPricings: [],
    preventivi: new Array<PreventivoModel>(),
    passengersInCar: new Array<Passeggero & AccordionItem & SavableItem>(),
    passengersApi: new PassengerApi(),
    baggage: 0,
    luggage: 0,
    extraAssistance: false,
    serviceTypesApi: new ServiceTypeApi(),
    carDisposal: new Array<TipoServizioT>(),
    rideType: null,
    driverApi: new DriverApi(),
    vehicleApi: new VehicleApi(),
    userStore: useUserStore(),
    useInternalCredit: false,
    promoApi: new PromoApi(),
    babySeats: new Array<string>(),
    nonEndedTrips: new Array<CustomerTripSummary>(),
    enededTrips: null,
    lastCreatedTrip: {
      tripToInsert: null,
      passengersInCar: new Array<Passeggero & AccordionItem & SavableItem>(),
      rideType: null,
      baggage: 0,
      luggage: 0,
      extraAssistance: false,
      babySeats: new Array<string>(),
    },
    currentPassengerCustomCustomerConfig: [],
    companyStore: useCompanyStore(),
    availableVehicles: [],
    rideNotifications: [],
    referencePassengerId: -1,
  }),
  getters: {
    // passengers of the trip
    populatedPassengers: (state) =>
      state.passengersInCar.filter(
        (p) =>
          (p.nome || p.idPasseggero) &&
          (state.rideType == "work" || p.idPasseggero > -1), // usually fake passengers have -1 as id
      ),
    isPersonalTrip: (state) => state.rideType === "private",
    currentMasterId: (state) => state.userStore.userInfo?.master[0]?.id || 0,

    /**
     * Invoicing stuff (mostly used in TripResume/TripEdit)
     */
    canSeeInvoicingCostCenter: (state) => (trip: Viaggio) => true,
    canSeeInvoicingOrder: (state) => (trip: Viaggio) =>
      state.userStore.currentCustomerConfig.etichettaCommessa,
    canSeeInvoicingField2: (state) => (trip: Viaggio) =>
      state.userStore.currentCustomerConfig.etichettaCampo2,
    canSeeInvoicingField4: (state) => (trip: Viaggio) =>
      state.userStore.currentCustomerConfig.etichettaCampo4,

    invoicingCostCenterValue: (state) => (trip: Viaggio) =>
      getCustomConfigFieldValue(
        1,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    invoicingOrderValue: (state) => (trip: Viaggio) =>
      getCustomConfigFieldValue(
        2,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    invoicingField2Value: (state) => (trip: Viaggio) =>
      getCustomConfigFieldValue(
        3,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    invoicingField4Value: (state) => (trip: Viaggio) =>
      getCustomConfigFieldValue(
        4,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),

    isInvoicingCostCenterMandatory: (state) => (trip: Viaggio) =>
      isCustomConfigFieldMandatory(
        1,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    isInvoicingOrderMandatory: (state) => (trip: Viaggio) =>
      isCustomConfigFieldMandatory(
        2,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    isInvoicingField2Mandatory: (state) => (trip: Viaggio) =>
      isCustomConfigFieldMandatory(
        3,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    isInvoicingField4Mandatory: (state) => (trip: Viaggio) =>
      isCustomConfigFieldMandatory(
        4,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    isPaxTelMandatory: (state) => (trip: Viaggio) =>
      isCustomConfigFieldMandatory(
        5,
        state.currentPassengerCustomCustomerConfig,
        null
      ),  
    isFlightNumberMandatory: (state) => (trip: Viaggio) =>
      isCustomConfigFieldMandatory(
        8,
        state.currentPassengerCustomCustomerConfig,
        null
      ),  

    isInvoicingCostCenterBlocked: (state) => (trip: Viaggio) =>
      isCustomConfigFieldBlocked(
        1,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    isInvoicingOrderBlocked: (state) => (trip: Viaggio) =>
      isCustomConfigFieldBlocked(
        2,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    isInvoicingField2Blocked: (state) => (trip: Viaggio) =>
      isCustomConfigFieldBlocked(
        3,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    isInvoicingField4Blocked: (state) => (trip: Viaggio) =>
      isCustomConfigFieldBlocked(
        4,
        state.currentPassengerCustomCustomerConfig,
        trip.td.toString(),
      ),
    isPaxTelBlocked: (state) => (trip: Viaggio) =>
      isCustomConfigFieldBlocked(
        5,
        state.currentPassengerCustomCustomerConfig,
        null,
      ),  
    isFlightNumberBlocked: (state) => (trip: Viaggio) =>
      isCustomConfigFieldBlocked(
        8,
        state.currentPassengerCustomCustomerConfig,
        null,
      ),
    /**
     * End invoicing stuff
     */
    totalCarsCost: (state) =>
      state.selectedPricings.reduce(
        (sum, p) => sum + (p.FavoriteTaxableAmount ?? p.totale),
        0,
      ),

    totalVatCarsCost: (state) =>
      state.selectedPricings.reduce(
        (sum, p) => sum + (p.FavoriteTaxAmount ?? p.totale),
        0,
      ),

    currentRoute: (state) =>
      state.tripToInsert.route
        ? (JSON.parse(state.tripToInsert.route) as GoogleRoute)
        : null,
  },
  actions: {
    async discoverRoute() {
      await this.doDiscoverRoute(this.tripToInsert);
      this.saveLocally();
    },

    async doDiscoverRoute(trip: Viaggio) {
      const userStore = useUserStore();
      this.uiStore.toggleLoading(true);
      try {
        if (!trip.origin || !trip.destination || !trip.data) {
          throw new Error("Missing fields");
        }
        const route = await this.tripApi.discoverRoute(
          trip.origin,
          trip.destination,
          trip.data,
          userStore.userInfo?.payload.iD_Azienda,
        );

        trip.route = JSON.stringify(route);
        trip.tot_km = parseFloat(
          (route.routes[0].legs[0].distance.value / 1000) as any,
        );
        trip.tot_H = parseFloat(
          (route.routes[0].legs[0].duration.value / 3600) as any,
        );
        return trip;
      } catch (e) {
        console.log("::DiscoverRoute " + (e as Error).message);
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    setRideRoute(ride: Viaggio, route: GoogleRoute) {
      ride.route = JSON.stringify(route);
      ride.tot_km = parseFloat(
        (route.routes[0].legs[0].distance.value / 1000) as any,
      );
      ride.tot_H = parseFloat(
        (route.routes[0].legs[0].duration.value / 3600) as any,
      );
    },

    updateDates(date: Date) {
      if (isNaN(date.getTime())) {
        console.error("<<<TRIP_STORE>>> Invalid date!", date);
        return;
      }
      this.tripToInsert.data = dateToDateTime(date);
      if (!this.tripToInsert.tot_H) {
        return;
      }
      date.setUTCMinutes(date.getUTCMinutes() + this.tripToInsert.tot_H * 60);
      this.tripToInsert.dataFine = dateToDateTime(date);
    },

    async calculatePricing() {
      const userStore = useUserStore();

      if (
        !this.tripToInsert.partenza ||
        !this.tripToInsert.destinazione ||
        !this.tripToInsert.origin?.lat ||
        !this.tripToInsert.origin?.lng ||
        (this.tripToInsert.td === 57 &&
          (!this.tripToInsert.destination?.lat ||
            !this.tripToInsert.destination?.lng ||
            !this.tripToInsert.route)) ||
        !this.tripToInsert.tot_H ||
        !this.tripToInsert.tot_km
      ) {
        window.location.href = "/";
        return;
      }
      this.selectedPricings = [];
      if (
        this.tripToInsert.td === 57 &&
        (this.tripToInsert.tot_H !==
          this.getRouteHoursDuration(this.tripToInsert) ||
          this.tripToInsert.tot_km !== this.getRouteKms(this.tripToInsert))
      ) {
        datadogRum.addError("HoursOrKmDontMatch", {
          ride: toRaw(this.tripToInsert),
          totH: this.tripToInsert.tot_H,
          totKm: this.tripToInsert.tot_km,
          routeTotH: this.getRouteHoursDuration(this.tripToInsert),
          routeTotKm: this.getRouteKms(this.tripToInsert),
        });
        this.setRideRoute(
          this.tripToInsert,
          JSON.parse(this.tripToInsert.route!) as GoogleRoute,
        );
      }

      this.uiStore.toggleLoading(true);

      if (this.tripToInsert.td === 2) {
        if (this.carDisposal.length === 0) {
          await this.getCarDisposalTypes();
        }
        const disposal = this.carDisposal.find(
          (d) => d.idTipoServizio === this.tripToInsert.iD_Tipo_Servizio,
        )!;
        this.tripToInsert.tot_H = disposal.ore!;
        this.tripToInsert.tot_km = disposal.km!;
      }

      if (this.tripToInsert.td === 57 && !this.tripToInsert.route) {
        await this.discoverRoute();
      }

      try {
        await this.loadPricingList();

        this.preventivi = await this.tripApi.calculateTrip({
          aziendaid: userStore.userInfo?.payload.iD_Azienda!,
          clienteid: userStore.userInfo?.payload.idCliente!,
          os: "web",
          viaggio: this.tripToInsert,
          token: userStore.accessToken,
          preventivo: this.preventivi.map((p) => {
            const seats = parseInt(p.seats);
            const pessangerNumber = isNaN(
              this.tripToInsert.numeroPersone as any,
            )
              ? 8
              : (this.tripToInsert.numeroPersone as number);
            let requiredVehicles = pessangerNumber / parseInt(p.seats);
            requiredVehicles += requiredVehicles % 1;
            requiredVehicles = Math.round(requiredVehicles);
            // requiredVehicles =
            //   requiredVehicles < p.quantity ? p.quantity : requiredVehicles;
            return {
              ...p,
              baggage: "0",
              quantity:
                !isNaN(seats) && requiredVehicles > 0 && seats != 0
                  ? requiredVehicles
                  : 1,
              currencyStart: ValuteListini[userStore.currentUserCurrency],
              currencyEnd: ValuteListini[userStore.currentUserCurrency],
              // exchangeRate:
              //   this.userStore.eurExchangeRate[userStore.currentUserCurrency],              
            };
          }),
          nazionePickup: this.tripToInsert.nazionePickup,
          nazioneDropOff: this.tripToInsert.nazioneDropOff,
          currency: ValuteListini[userStore.currentUserCurrency]
        });

        this.preventivi.map((p) => {
          const vehicleType = p.tipomezzo.id;
          const rule = this.userStore.customCustomerConfig.find(
            (c) => c.iD_Campo == 7 && c.iD_Valore === vehicleType,
          );
          p.blockedCustomConfig = rule != null && rule.bloccato;
        });

        datadogRum.addAction("calculatePricing", {
          pricings: toRaw(this.preventivi),
        });

        datadogRum.addAction(
          "calculatePricingConvertedCurrency",
          this.preventivi.map((p) => p.totale),
        );

        // this.preventivi = this.preventivi.filter((p) => p.totale != 0);

        if (this.preventivi.length === 0) {
          this.toast.warning("Error_no_pricing_contact_us");
        }

        const busIdx = this.preventivi.findIndex(
          (p) => p.tipomezzo.id === "b.png",
        );

        if (busIdx >= 0) {
          this.preventivi = [
            ...this.preventivi.filter((e, idx) => idx != busIdx),
            this.preventivi[busIdx],
          ];
        }
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async calculateExistingTripPricing(trip: Viaggio) {
      const userStore = useUserStore();
      this.uiStore.toggleLoading(true);
      if (trip.td === 2 && !trip.idViaggio) {
        if (this.carDisposal.length === 0) {
          await this.getCarDisposalTypes();
        }
        const disposal = this.carDisposal.find(
          (d) => d.idTipoServizio === trip.iD_Tipo_Servizio,
        )!;
        trip.tot_H = disposal.ore!;
        trip.tot_km = disposal.km!;
      }

      if (trip.td === 57 && !trip.route) {
        await this.doDiscoverRoute(trip);
      }

      try {
        const pricing = await this.tripApi.calculateTrip({
          aziendaid: userStore.userInfo?.payload.iD_Azienda!,
          clienteid: userStore.userInfo?.payload.idCliente!,
          os: "web",
          viaggio: trip,
          token: userStore.accessToken,
          preventivo: this.preventivi
            .filter((p) => p.tipomezzo.id === trip.catMezzo)
            .map((p) => ({
              ...p,
              quantity:
                Math.round(
                  (isNaN(trip.numeroPersone as any)
                    ? 7
                    : (trip.numeroPersone as number)) / parseInt(p.seats),
                ) || 1,
              currencyStart: ValuteListini[userStore.currentUserCurrency],
              currencyEnd: ValuteListini[userStore.currentUserCurrency],
              exchangeRate:
                this.userStore.eurExchangeRate[userStore.currentUserCurrency],
            })),
          nazionePickup: trip.nazionePickup,
          nazioneDropOff: trip.nazioneDropOff,
          currency: ValuteListini[userStore.currentUserCurrency],
        });

        if (pricing.length === 0) {
          throw new Error("Error_no_pricing_contact_us");
        }

        trip.importo =
          pricing[0].Legacy?.descrittore.TariffaFascia || pricing[0].totale;
        trip.preventivo = pricing[0].totale.toString();

        trip.naturaAliquotaNazione = pricing[0].Natura;
        trip.iDAliquota = pricing[0].IdAliquotaNazione;
        trip.aliquotaIva = pricing[0].AliquotaIva;
        trip.descrizioneAliquotaNazione = pricing[0].DescrizioneIva;
        trip.idValutaRichiesta = pricing[0].IdValutaRichiesta;
        trip.valutaRichiesta = pricing[0].CurrencyStart;
        trip.valutaListino = pricing[0].CurrencyEnd;
        trip.cambioValutaListino = pricing[0].ExchangeRate;
        trip.cambioValutaDaEuro = pricing[0].ExchangeRateFromEuro;

        trip.speseTratta = pricing[0].Legacy?.Spese;
        trip.speseImponibile = pricing[0].totale - trip.importo;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    saveLocally() {
      const toSave = toRaw({ ...this.tripToInsert });
      toSave.route = null;
      localStorage.setItem("trip", JSON.stringify(toSave));

      if (this.rideType) {
        localStorage.setItem("rideType", this.rideType);
      }
      localStorage.setItem(
        "pricings",
        JSON.stringify({
          selectedPricings: this.selectedPricings,
          pricings: this.preventivi,
          saveTime: Date.now(),
          passengersInCar: this.passengersInCar,
          rideNotifications: this.rideNotifications,
        }),
      );
    },

    getLSPricings() {
      const res = localStorage.getItem("preventivo");
      if (!res) {
        return [];
      }
      const preventivo = JSON.parse(res) as Array<PreventivoModel>;
      return preventivo;
    },
    getLocalTrip() {
      const res = localStorage.getItem("trip");
      if (!res) {
        return null;
      }
      const ride = JSON.parse(res) as Viaggio;
      if ((ride.partenza && !ride.origin?.lat) || !ride.origin?.lng) {
        ride.partenza = "";
      }
      if (
        (ride.destinazione && !ride.destination?.lat) ||
        !ride.destination?.lng
      ) {
        ride.destinazione = "";
      }
      return ride;
    },
    resetLocalStorage() {
      localStorage.removeItem("preventivo");
      localStorage.removeItem("trip");
      localStorage.removeItem("rideType");
      localStorage.removeItem("pricings");
    },
    reset() {
      this.resetLocalStorage();
      this.resetTripLists();
      this.tripToInsert = { ...TRIP_TO_INSERT_INITIAL_VALUE } as Viaggio;
      this.loadPricingList();
      this.useInternalCredit = false;
      this.extraAssistance = false;
      this.baggage = 0;
      this.luggage = 0;
      this.babySeats = [];
      this.passengersInCar = [];
      this.currentPassengerCustomCustomerConfig = [];
      this.setupPrivateOrCompanyTrip();
      this.selectedPricings = [];
      this.rideNotifications = [];
      this.referencePassengerId = -1;
    },
    recoverPreventivoFromLocal() {
      this.selectedPricings = this.getLSPricings();
      if (this.selectedPricings[0]) {
        this.createPassengersInCar(this.selectedPricings[0]);
      }
    },
    getLocalRideType() {
      return localStorage.getItem("rideType") as "work" | "private" | null;
    },

    createEmptyCarPassenger() {
      return {
        iD_Cliente: this.tripToInsert.iD_Cliente,
        iDAzienda: this.tripToInsert.iD_Azienda,
        shouldSave: false,
        idPasseggero: undefined,
        nome: "",
        cognome: "",
        email: "",
        cellulare: "",
        note: "",
        visualizzaPrezzoSlave: true,
        prefix: "",
      } as Partial<Passeggero & AccordionItem & SavableItem> as Passeggero &
        AccordionItem &
        SavableItem;
    },

    /**
     * Creates the passengers to be saved as ViaggiPasseggeri
     */
    async createPassengersInCar(preventivo: PreventivoModel) {
      const seats = Number(preventivo.seats === "7+" ? 8 : preventivo!.seats);

      if (isNaN(seats)) {
        console.error("Invalid number or seats");
        throw new Error(this.i18n.global.t("Error_generic"));
      }

      this.passengersInCar = new Array<
        Passeggero & AccordionItem & SavableItem
      >(seats)
        .fill(null as any)
        .map((_, idx) =>
          this.populatedPassengers[idx] !== undefined
            ? this.populatedPassengers[idx]
            : this.createEmptyCarPassenger(),
        );
    },

    updatePassengersInCar(
      passengers: Array<Passeggero & AccordionItem & SavableItem>,
    ) {
      this.passengersInCar = [...passengers];
    },

    async sendTrip(discount: ScontoCoupon | null, fileAllegati: File[]) {
      try {
        if (this.populatedPassengers.length == 0) {
          this.toast.error(this.i18n.global.t("Error_missing_passengers"));
          return;
        }
        if (this.rideNotifications.length == 0) {
          this.toast.error(
            this.i18n.global.t("Error_missing_notifications_receivers"),
          );
          return;
        }

        this.uiStore.toggleLoading(true);

        const sentTrips = await Promise.allSettled(
          this.selectedPricings.map((p) =>
            this.createCarRide(p, discount, fileAllegati),
          ),
        );

        await this.userStore.getUserCredit();

        if (sentTrips.filter((e) => e.status === "rejected").length > 0) {
          throw new Error("Error_booking_cars");
        }

        if (this.lastCreatedTrip.tripToInsert === null) {
          this.lastCreatedTrip.tripToInsert = {
            ...this.tripToInsert,
          };
          delete (this.lastCreatedTrip.tripToInsert as any).idViaggio;

          this.swapFromTo(this.lastCreatedTrip.tripToInsert);

          const startDate = new Date(
            this.lastCreatedTrip.tripToInsert.dataFine,
          );
          startDate.setHours(startDate.getHours() + 1);
          this.lastCreatedTrip.tripToInsert.data = dateToDateTime(startDate);

          const endDate = new Date(this.lastCreatedTrip.tripToInsert.dataFine);
          endDate.setHours(endDate.getHours() + 1);
          this.lastCreatedTrip.tripToInsert.dataFine = dateToDateTime(endDate);

          this.lastCreatedTrip.passengersInCar = [...this.passengersInCar];
          this.lastCreatedTrip.rideType = this.rideType;
          this.lastCreatedTrip.baggage = this.baggage;
          this.lastCreatedTrip.luggage = this.luggage;
          this.lastCreatedTrip.extraAssistance = this.extraAssistance;
          this.lastCreatedTrip.babySeats = this.babySeats;
          this.uiStore.TripToastSuccess();
        } else {
          this.lastCreatedTrip.tripToInsert = null;
          this.lastCreatedTrip.rideType = null;
          this.lastCreatedTrip.passengersInCar = [];
          this.toast.success(this.i18n.global.t("ToastTripSuccessTitle"), {
            position: POSITION.TOP_CENTER,
          });
        }

        this.router.push("/");
        this.reset();
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        console.error(e);
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async createCarRide(
      pricing: PreventivoModel,
      discount: ScontoCoupon | null,
      fileAllegati: File[],
    ) {
      try {
        const userStore = useUserStore();

        if (pricing.quantity > 1) {
          pricing.totale = pricing.totale / pricing.quantity;

          if (
            pricing.Legacy != undefined &&
            pricing.Legacy.descrittore.TariffaFascia
          ) {
            pricing.Legacy.descrittore.TariffaFascia =
              pricing.Legacy.descrittore.TariffaFascia / pricing.quantity;
          }
        }
        for (let car = 0; car < pricing.quantity; car++) {
          const tripToInsert = { ...this.tripToInsert };
          tripToInsert.nome_Cognome =
            tripToInsert.cliente = `${userStore.userInfo?.payload.nome} ${userStore.userInfo?.payload.cognome}`;

          tripToInsert.email_Prenota = this.userStore.userInfo!.email;

          // this.createLegacyPassengerInfo(
          //   tripToInsert,
          //   this.populatedPassengers,
          // );

          tripToInsert.naturaAliquotaNazione = pricing.Natura;
          tripToInsert.iDAliquota = pricing.IdAliquotaNazione;
          tripToInsert.aliquotaIva = pricing.AliquotaIva;
          tripToInsert.descrizioneAliquotaNazione = pricing!.DescrizioneIva;
          tripToInsert.idValutaRichiesta = pricing!.IdValutaRichiesta;
          tripToInsert.valutaRichiesta = pricing.CurrencyStart;
          tripToInsert.valutaListino = pricing.CurrencyEnd;
          tripToInsert.cambioValutaListino = pricing.ExchangeRate;
          tripToInsert.cambioValutaDaEuro = pricing.ExchangeRateFromEuro;

          tripToInsert.referenceCurrency = pricing.referenceCurrency;
          tripToInsert.favoriteCurrency = pricing.favoriteCurrency;
          tripToInsert.pricelistCurrency = pricing.pricelistCurrency;
          tripToInsert.referenceToFavoriteRate =
            pricing.referenceToFavoriteRate;
          tripToInsert.referenceToPricelistRate =
            pricing.referenceToPricelistRate;

          tripToInsert.importo =
            pricing!.Legacy?.descrittore.TariffaFascia || pricing.totale;

          tripToInsert.speseTratta = pricing.Legacy?.Spese;

          tripToInsert.preventivo = pricing.totale.toString();
          tripToInsert.catMezzo = pricing.tipomezzo.id;
          tripToInsert.importoFissato = pricing.Legacy?.LockedPrices ?? false;
          tripToInsert.catMezzo = pricing.tipomezzo.id;

          const extras = [
            "Bagagli da stiva: " + (this.baggage || 0),
            "Bagagli a mano: " + (this.luggage || 0),
          ];

          if (this.extraAssistance) {
            extras.push("Assistenza speciale: " + this.extraAssistance);
          }

          if (this.babySeats.length > 0) {
            extras.push("Seggiolino: " + this.babySeats.join(","));
          }

          if (discount) {
            extras.push(
              `Discount: (${discount.codice}) ${
                discount.percentuale
                  ? discount.percentuale + "%"
                  : discount.valore + tripToInsert.valutaRichiesta
              }`,
            );
          }

          tripToInsert.spieg_extra = extras.join(", ");

          this.setTripPaymentFlags(tripToInsert);

          let internalCreditReq: CreditoRequest | null = null;
          if (this.useInternalCredit && this.userStore.credito > 0) {
            const vattedImport = getVattedPrice(
              tripToInsert.importo!,
              tripToInsert.aliquotaIva,
              false,
            );
            const usedCredit = Math.min(vattedImport, this.userStore.credito);
            internalCreditReq = {
              idazienda: tripToInsert.iD_Azienda.toString(),
              // idcliente: this.tripToInsert.iD_Cliente.toString(),
              idcliente: this.userStore.currentIdCliente!.toString(),
              importo: -usedCredit,
              note: "",
            };
            tripToInsert.importo = getNetPriceFromGross(
              vattedImport - usedCredit,
              tripToInsert.aliquotaIva,
              false,
            );
            this.userStore.credito -= usedCredit;
          }

          const passengersResults = await Promise.allSettled(
            this.populatedPassengers.map(async (passenger) => {
              passenger.idPasseggero =
                passenger.idPasseggero != -1 ? passenger.idPasseggero : 0;

              if (!passenger.idPasseggero) {
                passenger.passeggero = `${passenger.nome} ${passenger.cognome}`;
              }

              if (!passenger.cellulare) {
                delete passenger.cellulare;
              } else {
                passenger.cellulare = (
                  passenger.prefix + passenger.cellulare
                ).replace(/ /g, "");
              }

              if (!passenger.idPasseggero && passenger.shouldSave) {
                passenger.telefono = passenger.cellulare;
                passenger.idPasseggero =
                  await this.passengersApi.createPassenger(
                    passenger,
                    this.userStore.isCompany || false,
                    passenger.visualizzaPrezzoSlave,
                  );
              }

              return passenger;
            }),
          );
          datadogRum.addAction("createdPassengers");

          const ridePassengers = this.populatedPassengers.map((passenger) => ({
            cellularePasseggero: passenger.idPasseggero
              ? null
              : passenger.cellulare,
            passeggero: passenger.passeggero,
            emailPasseggero: passenger.email,
            idViaggio: tripToInsert.idViaggio,
            idPasseggero: passenger.idPasseggero ? passenger.idPasseggero : 0,
            idCliente: tripToInsert.iD_Cliente,
            notificaDettagli: false,
            notePasseggero: passenger.note,
            creationDate: null,
            id: null,
            idAzienda: passenger.idAzienda,
          }));

          const notificationEmails = this
            .rideNotifications as CentroNotifiche[];

          tripToInsert.passeggero = "";

          if (!tripToInsert.idViaggio) {
            datadogRum.addAction("sendTrip", {
              ride: {
                ...toRaw({
                  ride: { ...tripToInsert, route: null },
                  ridePassengers,
                  notificationEmails,
                }),
              },
              route: {
                end: this.getRouteEnd(this.tripToInsert),
                start: this.getRouteStart(this.tripToInsert),
                km: this.getRouteKms(this.tripToInsert),
                h: this.getRouteHoursDuration(this.tripToInsert),
              },
            });
            tripToInsert.idViaggio = await this.tripApi.sendTrip(
              tripToInsert,
              ridePassengers,
              notificationEmails,
              "cliente",
              tripToInsert.iD_Azienda,
              fileAllegati,
              this.useInternalCredit,
            );
            datadogRum.addAction("lastCreatedTripId", {
              id: tripToInsert.idViaggio,
            });
            if (tripToInsert.importo === 0) {
              datadogRum.addAction("RideNetIsZero", {
                id: tripToInsert.idViaggio,
              });
            }
          } else {
            await this.tripApi.updateTrip(
              tripToInsert,
              ridePassengers,
              notificationEmails,
              "cliente",
              Number(tripToInsert.iD_Azienda),
              fileAllegati,
              this.useInternalCredit,
            );
            datadogRum.addAction("sendTrip->update", {
              ride: { ...toRaw(tripToInsert) },
              route: {
                end: this.getRouteEnd(this.tripToInsert),
                start: this.getRouteStart(this.tripToInsert),
                km: this.getRouteKms(this.tripToInsert),
                h: this.getRouteHoursDuration(this.tripToInsert),
              },
              ridePassengers,
              notificationEmails,
            });
          }

          if (
            passengersResults.filter((p) => p.status === "rejected").length > 0
          ) {
            this.toast.warning(
              "Viaggio creato con successo ma alcuni passeggeri non sono stati salvati",
            );
            datadogRum.addAction("createdPassengers->error");
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          this.toast.error(e.message);
        }
        datadogRum.addError("errorSendTrip", e as any);
        throw e;
      }
    },

    async getCarDisposalTypes() {
      const userStore = useUserStore();
      if (!this.tripToInsert.origin?.lat || !this.tripToInsert.origin?.lng) {
        return;
      }
      try {
        this.uiStore.toggleLoading(true);
        this.carDisposal = await this.serviceTypesApi.journeyByPosition(
          userStore.currentIdCliente!,
          this.tripToInsert.origin.lat,
          this.tripToInsert.origin.lng,
          userStore.currentIdAzienda!,
        );
        if (this.carDisposal.length === 0) {
          throw new Error("Error_no_service_available");
        }
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    /**
     * Used to setup default trip values
     * Company values are setted using the HomeSearchComponent,
     * for private users values are "static" and have to be setted in this way
     */
    setupPrivateOrCompanyTrip(goAsPrivate: boolean = false) {
      const userStore = useUserStore();
      if (userStore.isPrivate || goAsPrivate) {
        this.setupPrivateTrip();
        return;
      }
      const rideType = this.getLocalRideType();
      if (
        userStore.userInfo!.master.length > 0 &&
        (rideType === "work" || !rideType)
      ) {
        this.setupCompanyTrip();
      }
    },

    setupCompanyTrip() {
      localStorage.setItem("rideType", "work");
      this.rideType = "work";
      // this.tripToInsert.iD_Cliente = this.userStore.userInfo!.master[0].id;
      this.tripToInsert.iD_Cliente = this.userStore.userInfo!.payload.idCliente;
      this.tripToInsert.iD_Azienda =
        this.userStore.userInfo!.payload.iD_Azienda;
      this.tripToInsert.noteGenerali = "FATTURA AZIENDALE ";
      this.tripToInsert.passeggero =
        this.tripToInsert.passeggero ||
        this.userStore.userInfo!.payload.ragioneSociale.trim();
      this.setTripExtraInvoicingFields(this.tripToInsert);
      this.tripToInsert.fatturaPrivato = false;
    },

    setTripExtraInvoicingFields(tripToUpdate: Viaggio) {
      if (this.rideType != "work") {
        return;
      }
      tripToUpdate.cdc =
        ((tripToUpdate.idViaggio && tripToUpdate.cdc) ||
          this.invoicingCostCenterValue(this.tripToInsert)) ??
        "";
      tripToUpdate.commessa =
        ((tripToUpdate.idViaggio && tripToUpdate.commessa) ||
          this.invoicingOrderValue(this.tripToInsert)) ??
        "";
      tripToUpdate.campo2 =
        (tripToUpdate.idViaggio && tripToUpdate.campo2) ||
        this.invoicingField2Value(this.tripToInsert);
      tripToUpdate.campo4 =
        (tripToUpdate.idViaggio && tripToUpdate.campo4) ||
        this.invoicingField4Value(this.tripToInsert);
    },

    setupPrivateTrip() {
      localStorage.setItem("rideType", "private");
      this.rideType = "private";
      this.tripToInsert.iD_Cliente = this.userStore.userInfo!.payload.idCliente;
      this.tripToInsert.iD_Azienda =
        this.userStore.userInfo!.payload.iD_Azienda;
      this.tripToInsert.passeggero = `${this.userStore.userInfo?.payload.nome} ${this.userStore.userInfo?.payload.cognome}`;
      this.tripToInsert.noteGenerali = "FATTURA A PRIVATO ";
      this.tripToInsert.fatturaPrivato = true;
    },

    async *getNonEndedTrips(
      pagination: PagingSelectionModel,
      signal?: AbortSignal,
    ) {
      if (signal) {
        signal.addEventListener("abort", () => {
          throw new Error("aborted");
        });
      }
      if (this.nonEndedTrips.length > 0) {
        yield this.nonEndedTrips;
        return;
      }
      try {
        this.uiStore.toggleLoading(true);
        const trips: Array<CustomerTripSummary> =
          await this.tripApi.getViaggiNonConclusiCliente(pagination);
        this.uiStore.toggleLoading(false);
        yield trips;

        const uniquePassengersData = (this.companyStore.passengers =
          await this.getAllCompaniesPassengers());

        const limitedTrips = trips.slice(0, 100);
        await Promise.allSettled(
          limitedTrips.map(async (trip) => {
            const passengers = await this.tripApi.getTripPassengers(
              trip.idViaggio,
              trip.iD_Azienda,
              false,
            );
            trip.passeggeri = passengers;
          }),
        );

        trips.forEach((t) => {
          t.passeggeri?.forEach((p) => {
            if (p.viaggiPasseggeri.idPasseggero) {
              p.passeggero = uniquePassengersData.find(
                (pd) => pd.idPasseggero == p.viaggiPasseggeri.idPasseggero,
              );
            }
          });
        });
        this.nonEndedTrips = trips;
        yield trips;
        return;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return [];
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },
    async getNonEndedTripsLite(
      pagination: PagingSelectionModel,
      signal?: AbortSignal,
    ) {
      if (signal) {
        signal.addEventListener("abort", () => {
          throw new Error("aborted");
        });
      }
      try {
        this.uiStore.toggleLoading(true);
        const trips: Array<CustomerTripSummary> =
          await this.tripApi.getViaggiNonConclusiCliente(pagination);
        return trips;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return [];
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },
    async *getEndedTrips(pagination: PagingSelectionModel) {
      if (this.enededTrips !== null) {
        /**
         * Dupe enededTrips to avoid editing the map from external sources
         */
        const enededTrips = new Map<number, Array<CustomerTripSummary>>();
        this.enededTrips.forEach((values, key) => {
          enededTrips.set(key, values);
        });

        yield enededTrips;
        return;
      }
      try {
        this.uiStore.toggleLoading(true);
        const trips: Array<CustomerTripSummary> =
          await this.tripApi.getViaggiConclusiCliente(pagination);
        this.uiStore.toggleLoading(false);

        const uniqueInvoices = new Set(
          trips
            .sort(
              (a, b) => new Date(b.data).getTime() - new Date(a.data).getTime(),
            )
            .map((e) => e.iD_Fattura),
        );

        this.enededTrips = new Map<number, Array<CustomerTripSummary>>();

        uniqueInvoices.forEach((invoiceId) =>
          this.enededTrips!.set(
            invoiceId,
            trips.filter((t) => t.iD_Fattura === invoiceId),
          ),
        );

        /**
         * Dupe enededTrips to avoid editing the map from external sources
         */
        const enededTrips = new Map<number, Array<CustomerTripSummary>>();
        this.enededTrips.forEach((values, key) => {
          enededTrips.set(key, values);
        });

        yield enededTrips;

        const limitedTrips = trips.slice(0, 100);
        await Promise.allSettled(
          limitedTrips.map(async (trip) => {
            const passengers = await this.tripApi.getTripPassengers(
              trip.idViaggio,
              trip.iD_Azienda,
              false,
            );
            trip.passeggeri = passengers;
          }),
        );

        const uniquePassengersData = (this.companyStore.passengers =
          await this.getAllCompaniesPassengers());

        trips.forEach((t) => {
          t.passeggeri?.forEach((p) => {
            if (p.viaggiPasseggeri.idPasseggero) {
              p.passeggero = uniquePassengersData.find(
                (pd) => pd.idPasseggero == p.viaggiPasseggeri.idPasseggero,
              );
            }
          });
        });

        yield enededTrips;
        return;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return new Map<number, Array<CustomerTripSummary>>();
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },
    deleteAllegato(id: number, rideId: number, idazienda: number) {
      try {
        this.tripApi.deleteFileAllegatoAsync(id, rideId, idazienda);
      } catch (error) {
        this.toast.error(this.i18n.global.t((error as Error).message));
      } finally {
        location.reload();
      }
    },

    /**
     * Get all passengers for each master of this user
     */
    async getAllCompaniesPassengers() {
      const userStore = useUserStore();
      try {
        this.uiStore.toggleLoading(true);
        const res = await Promise.allSettled(
          userStore.userInfo!.master.map((master) =>
            this.passengersApi.getPasseggeri(
              master.id,
              userStore.userInfo!.payload.iD_Azienda,
            ),
          ),
        );
        return res
          .filter((p) => p.status === "fulfilled")
          .flatMap((p) => (p as PromiseFulfilledResult<Passeggero[]>).value);
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return [];
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async updateTrip(
      trip: Viaggio,
      tripPassengers: Array<ViaggiPasseggeriWithPasseggero>,
      existingNotificationEmails: CentroNotifiche[],
      usedCredit: number,
      discount: ScontoCoupon | null,
      originalPrice: number,
      fileAllegati: File[],
    ) {
      try {
        this.uiStore.toggleLoading(true);

        await this.userStore.getUserCredit();

        await Promise.allSettled(
          this.populatedPassengers.map(async (carPassenger, idx) => {
            carPassenger.idPasseggero =
              carPassenger.idPasseggero != -1 ? carPassenger.idPasseggero : 0;
            if (!carPassenger.idPasseggero) {
              carPassenger.passeggero = `${carPassenger.nome} ${carPassenger.cognome}`;
            }

            if (!carPassenger.cellulare) {
              delete carPassenger.cellulare;
            } else {
              carPassenger.cellulare = (
                carPassenger.prefix + carPassenger.cellulare
              ).replace(/ /g, "");
            }

            if (!carPassenger.idPasseggero && carPassenger.shouldSave) {
              carPassenger.telefono = carPassenger.cellulare;
              carPassenger.idPasseggero =
                await this.passengersApi.createPassenger(
                  carPassenger,
                  true,
                  carPassenger.visualizzaPrezzoSlave,
                );
            }
          }),
        );

        const ridePassengers = this.populatedPassengers.map(
          (passenger, idx) => ({
            cellularePasseggero: passenger.idPasseggero
              ? null
              : passenger.cellulare,
            passeggero: passenger.idPasseggero ? null : passenger.passeggero,
            emailPasseggero: passenger.idPasseggero ? null : passenger.email,
            idViaggio: trip.idViaggio,
            idPasseggero: passenger.idPasseggero ? passenger.idPasseggero : 0,
            idCliente: trip.iD_Cliente,
            notificaDettagli: false,
            notePasseggero: passenger.note,
            creationDate: null,
            id: tripPassengers[idx]?.viaggiPasseggeri.id,
            idAzienda: passenger.idAzienda || this.userStore.currentIdAzienda!,
          }),
        );

        datadogRum.addAction("updatedPassengers");

        // this.createLegacyPassengerInfo(trip, this.populatedPassengers);

        let internalCreditReq: CreditoRequest | null = null;

        const initialPrice = trip.importo!;

        console.log(
          "initialPrice",
          initialPrice,
          originalPrice,
          originalPrice === initialPrice,
          usedCredit,
        );

        const netPrice = trip.importo!;

        console.log("netPrice", netPrice);

        trip.importo! = netPrice;

        console.log(
          "converted trip importo",
          trip.importo,
          "usedCredit",
          usedCredit,
        );

        datadogRum.addAction("priceCalculation", {
          initialPrice,
          originalPrice,
          usedCredit,
          netPrice,
          convertedRidePrice: trip.importo,
        });

        if (discount && originalPrice !== initialPrice) {
          //reapply only on new pricing
          console.log("discounted net", trip.importo);
          datadogRum.addAction("applyDiscount", {
            discountedNet: trip.importo,
            discount,
          });
        }

        console.log("final import", trip.importo);

        datadogRum.addAction("finalImport", { finalNet: trip.importo });

        this.setTripPaymentFlags(trip);

        if (trip.importo === 0) {
          datadogRum.addAction("RideNetIsZeroUpdate", {
            id: trip.idViaggio,
          });
        }

        await this.tripApi.updateTrip(
          trip,
          ridePassengers,
          this.rideNotifications as CentroNotifiche[],
          this.userStore.userInfo?.role,
          trip.iD_Azienda,
          fileAllegati,
          this.useInternalCredit,
        );

        datadogRum.addAction("updateTrip", {
          trip: toRaw({ ...trip, route: null }),
          route: {
            end: this.getRouteEnd(trip),
            start: this.getRouteStart(trip),
            km: this.getRouteKms(trip),
            h: this.getRouteHoursDuration(trip),
          },
        });

        await this.userStore.getUserCredit();

        this.toast.success(this.i18n.global.t("TripUpdateSuccess"));

        this.reset();
        return true;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        datadogRum.addError("errorUpdateTrip", e as any);
        return false;
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getTrip(id: number, idazienda: number) {
      try {
        this.uiStore.toggleLoading(true);
        const trip = await this.tripApi.getTrip(id);
        const passengers = await this.tripApi.getTripPassengers(
          trip.idViaggio,
          trip.iD_Azienda,
          false,
        );
        const uniquePassengers = Array.from(
          new Set(
            passengers
              .filter((p) => p.viaggiPasseggeri.idPasseggero)
              .map((p) => p.viaggiPasseggeri.idPasseggero!),
          ),
        );
        const uniquePassengersData = await Promise.all(
          uniquePassengers.map((id) => this.passengersApi.getPassengerById(id)),
        );
        passengers.forEach((p) => {
          if (p.viaggiPasseggeri.idPasseggero) {
            p.passeggero = uniquePassengersData.find(
              (pd) => pd.idPasseggero == p.viaggiPasseggeri.idPasseggero,
            );
          }
        });
        const allegati = await this.tripApi.getFileAllegati(
          trip.idViaggio,
          trip.iD_Azienda,
        );

        let mezzo: Mezzi | undefined = undefined;
        if (trip.iD_Mezzo) {
          mezzo = await this.vehicleApi.getVehicleById(
            trip.iD_Mezzo,
            trip.iD_Azienda,
          );
        }
        if (trip.iD_Autista) {
          const driver = await this.driverApi.getDriverById(trip.iD_Autista);
          return { trip, driver, passengers, mezzo, allegati };
        }

        return { trip, passengers, mezzo, allegati };
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return null;
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getVehicles() {
      try {
        this.uiStore.toggleLoading(true);
        return this.tripApi.getVehicleList();
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return [];
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async deleteTrip(trip: Viaggio) {
      try {
        this.uiStore.toggleLoading(true);
        const res = await this.tripApi.deleteTrip(trip);
        this.toast.success(res);
        return true;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return false;
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async canEditTrip(trip: Viaggio) {
      if (!this.showEdit(trip)) {
        return false;
      }

      const minReservationTimeInfo = await this.tripApi.getMinReservationTime(
        trip.iD_Cliente,
        trip.puntoPartenza!.lat,
        trip.puntoPartenza!.long,
        trip.nazionePickup,
        trip.iD_Azienda,
      );
      const localTime = new Date(
        minReservationTimeInfo.localTime?.replace(
          // force JS to interpret the date as UTC and avoid native conversions to client/local time
          "+00:00",
          "Z",
        ) ?? new Date(),
      );
      const rideTime = new Date(trip.data + "Z");

      const diff = (rideTime.getTime() - localTime.getTime()) / 60000;
      const updateMinutes =
        minReservationTimeInfo.tempo_aggiornamento ??
        this.userStore.customerConfigs[0].tempo_Aggiornamento ??
        0;
      const editable = diff > 0 && diff > updateMinutes;
      if (
        !editable ||
        (!this.userStore.isMaster &&
          trip.iD_Cliente !== this.userStore.currentIdCliente)
      ) {
        return false;
      }
      return true;
    },

    /**
     * Utility to show edit or discard actions (eg show user penalty option in discard dialog)
     */
    showEdit(trip: Viaggio | CustomerTripSummary) {
      if (trip.fatturato || trip.concluso || trip.annullato || trip.inCorso) {
        return false;
      }
      return true;
    },

    hasDiscardPenalty(trip: Viaggio | CustomerTripSummary) {
      const tripDate = new Date(trip!.data);
      const diff = (tripDate.getTime() - Date.now()) / 60000;

      if (this.userStore.isPrivate) {
        return (
          diff > (this.userStore.customerConfigs[0].tempo_Cancellazione ?? 0)
        );
      }
      return (
        diff >
        (this.userStore.customerConfigs.find(
          (c) => c.iD_Cliente === trip.iD_Cliente,
        )?.tempo_Cancellazione ??
          this.userStore.customerConfigs[0].tempo_Aggiornamento ??
          0)
      );
    },

    async loadPricingList() {
      try {
        this.uiStore.toggleLoading(true);
        // const vehicleCategories = await this.vehicleApi.getCategories();
        // this.preventivi = vehicleCategories.map(
        //   (e) =>
        //     ({
        //       childseat: 0,
        //       quantity: 1,
        //       totale: 0,
        //       baggage: "0",
        //       luggage: "0",
        //       seats: "0",
        //       tipomezzo: {
        //         id: e.id,
        //         description: e.description,
        //         summary: e.summary,
        //         title: e.summary,
        //       },
        //     } as PreventivoModel)
        // );
        this.availableVehicles = await this.vehicleApi.getAvailableVehicleTypes(
          this.userStore.currentIdAzienda || 1,
        );
        // this.preventivi =
        //   (await this.vehicleApi.getCategories()) as Array<PreventivoModel>;
        this.preventivi = this.availableVehicles.map((v) => ({
          tipomezzo: {
            id: v.tipoMezzo,
            title: v.catMezzo,
          },
        })) as Array<PreventivoModel>;

        /**
         * Custom config vehicles check (filters only allowed vehicles)
         */
        if (this.userStore.customCustomerConfig.length > 0) {
          // this.preventivi = this.preventivi.filter((p) => {
          //   const vehicleType = p.tipomezzo.id;
          //   const rule = this.userStore.customCustomerConfig.find(
          //     (c) => c.iD_Campo == 7 && c.iD_Valore === vehicleType,
          //   );
          //   return !rule || !rule.bloccato;
          // });
        }
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return false;
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getTripUsedCredits(trip: Viaggio) {
      try {
        this.uiStore.toggleLoading(true);
        const usedCredits = await this.promoApi.getUsedCredits(
          trip.iD_Cliente,
          trip.iD_Azienda,
        );
        return usedCredits.filter((c) => c.idViaggio === trip.idViaggio);
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return [];
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    setTripPaymentFlags(trip: Viaggio) {
      return;
    },

    /**
     * Applied discounts to gross trip price
     */
    applyTripDiscount(trip: Viaggio, discount: ScontoCoupon) {
      trip.importo! -= getTripDiscountValue(
        getNetPriceFromGross(trip.importo!, trip.aliquotaIva),
        discount,
      );
    },

    resetTripLists() {
      this.enededTrips = null;
      this.nonEndedTrips = [];
    },

    setupTripFromLastSent() {
      if (this.lastCreatedTrip.tripToInsert) {
        this.tripToInsert = { ...this.lastCreatedTrip.tripToInsert };
        this.passengersInCar = [...this.lastCreatedTrip.passengersInCar];
        this.rideType = this.lastCreatedTrip.rideType;
        this.baggage = this.lastCreatedTrip.baggage;
        this.luggage = this.lastCreatedTrip.luggage;
        this.extraAssistance = this.lastCreatedTrip.extraAssistance;
        this.babySeats = [...this.lastCreatedTrip.babySeats];
      }
    },

    swapFromTo(trip: Viaggio) {
      if (!trip.origin || !trip.destination || !trip.destinazione) {
        return;
      }

      const origin = { ...trip.origin! };
      const destination = { ...trip.destination! };
      trip.origin = destination;
      trip.puntoPartenzaLat = destination!.lat;
      trip.puntoPartenzaLong = destination!.lng;
      const start = trip.partenza;
      trip.partenza = trip.destinazione;
      trip.destinazione = start;
      trip.destination = origin;
      trip.puntoDestinazioneLat = origin.lat;
      trip.puntoDestinazioneLong = origin.lng;
    },

    async fetchFirstPassengerCustomConfig(
      passengers: Array<Passeggero>,
      tripToUpdate: Viaggio,
    ) {
      try {
        if (
          this.tripToInsert.passeggero === NEW_PASSENGER ||
          this.populatedPassengers.length == 0 ||
          this.populatedPassengers[0]?.idPasseggero == -1
        ) {
          console.log(
            `Current passenger is ${
              this.tripToInsert.passeggero === NEW_PASSENGER
                ? "new"
                : "the master"
            }, using logged-in user custom confing`,
          );
          this.currentPassengerCustomCustomerConfig =
            this.userStore.customCustomerConfig;
          return;
        }
        this.uiStore.toggleLoading(true);
        const passenger = passengers.find(
          (p) => p.idPasseggero === this.passengersInCar[0].idPasseggero,
        );
        if (!passenger) {
          console.error("Cannot find passenger from trip passengers");
          // throw new Error(this.i18n.global.t("Error_server"));
          return;
        }
        if (
          this.populatedPassengers.length >= 1 &&
          passenger.idPasseggero > 0
        ) {
          this.currentPassengerCustomCustomerConfig =
            await this.userStore.customerApi.getCustomerCustomConfiguration(
              passenger?.iD_Cliente,
              passenger?.idAzienda,
            );
        } else if (
          passenger.idPasseggero === -1 // it's the master
        ) {
          this.currentPassengerCustomCustomerConfig =
            this.userStore.customCustomerConfig;
        }
        this.setTripExtraInvoicingFields(tripToUpdate);
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    createLegacyPassengerInfo(
      trip: Viaggio,
      passengersInCar: Array<Passeggero & AccordionItem & SavableItem>,
    ) {
      trip.email_cliente =
        passengersInCar.map((p) => p.email).join("; ") ??
        this.userStore.userInfo!.email;

      trip.cellularePasseggero =
        passengersInCar
          .filter((p) => p.cellulare)
          .map((p) =>
            (p.prefix && !p.cellulare?.startsWith(p.prefix)
              ? p.prefix + p.cellulare
              : p.cellulare!
            ).replace(/ /g, ""),
          )
          .join("; ") ?? this.userStore.userInfo!.phone;

      trip.passeggero =
        passengersInCar.length > 0
          ? passengersInCar.map((p) => `${p.nome} ${p.cognome}`).join("; ")
          : this.userStore.userInfo?.payload.ragioneSociale || "";
    },

    async requestNewCarQuantity(car: PreventivoModel) {
      try {
        this.uiStore.toggleLoading(true);
        const newPricings = await this.tripApi.calculateTrip({
          aziendaid: this.userStore.userInfo?.payload.iD_Azienda!,
          clienteid: this.userStore.userInfo?.payload.idCliente!,
          os: "web",
          viaggio: this.tripToInsert,
          token: this.userStore.accessToken,
          preventivo: [car],
          nazionePickup: this.tripToInsert.nazionePickup,
          nazioneDropOff: this.tripToInsert.nazioneDropOff,
          currency: ValuteListini[this.userStore.currentUserCurrency],
        });

        this.preventivi = this.preventivi.map((p) =>
          p.tipomezzo.id === newPricings[0].tipomezzo.id ? newPricings[0] : p,
        );
        this.selectedPricings = this.selectedPricings.map((p) =>
          p.tipomezzo.id === newPricings[0].tipomezzo.id ? newPricings[0] : p,
        );
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    resetLastCreatedTrip() {
      this.lastCreatedTrip = {
        tripToInsert: null,
        passengersInCar: [],
        rideType: null,
        baggage: 0,
        luggage: 0,
        extraAssistance: false,
        babySeats: new Array<string>(),
      };
    },

    getPassengersNumber(trip: Viaggio | CustomerTripSummary) {
      if (
        typeof trip.numeroPersone === "string" &&
        isNaN(parseInt(trip.numeroPersone))
      ) {
        return 7;
      }
      return parseInt(trip.numeroPersone as string);
    },

    resetRoute() {
      this.tripToInsert.route = null;
      this.tripToInsert.tot_H = 0;
      this.tripToInsert.tot_km = 0;
    },
    getRouteStart(ride: Viaggio) {
      if (!ride.route) {
        return null;
      }
      const route = JSON.parse(ride.route) as GoogleRoute;
      return route.routes[0].legs[0].start_address;
    },
    getRouteEnd(ride: Viaggio) {
      if (!ride.route) {
        return null;
      }
      const route = JSON.parse(ride.route) as GoogleRoute;
      return route.routes[0].legs[0].end_address;
    },
    getRouteHoursDuration(ride: Viaggio) {
      if (!ride.route) {
        return null;
      }
      const route = JSON.parse(ride.route) as GoogleRoute;
      return route.routes[0].legs[0].duration.value / 3600;
    },
    getRouteKms(ride: Viaggio) {
      if (!ride.route) {
        return null;
      }
      const route = JSON.parse(ride.route) as GoogleRoute;
      return route.routes[0].legs[0].distance.value / 1000;
    },
    async initRideResumePage() {
      let customer: ClientiModel;
      if (
        this.passengersInCar[0].idPasseggero == -1 // it's the account holder
      ) {
        customer = await this.userStore.customerApi.getCompanyCustomer(
          this.userStore.currentIdCliente!,
          this.userStore.currentIdAzienda!,
        );
      } else {
        customer = await this.userStore.customerApi.getCompanyCustomer(
          this.passengersInCar[0].iD_Cliente,
          this.passengersInCar[0].idAzienda || 1,
        );
      }

      if (
        customer.email_Conferma1 &&
        customer.email_Conferma1 != customer.email_Conferma2 &&
        this.rideNotifications.find(
          (e) => e.destinatario == customer.email_Conferma1,
        ) == undefined
      ) {
        this.rideNotifications.push({
          abilitato: true,
          destinatario: customer.email_Conferma1,
          nomeCliente: this.userStore.userInfo!.payload.ragioneSociale,
          idCliente: this.userStore.userInfo!.payload.idCliente,
          idAzienda: this.userStore.currentIdAzienda || 1,
        });
      }

      if (
        customer.email_Conferma2 &&
        customer.email_Conferma1 != customer.email_Conferma2 &&
        this.rideNotifications.find(
          (e) => e.destinatario == customer.email_Conferma2,
        ) == undefined
      ) {
        this.rideNotifications.push({
          abilitato: true,
          destinatario: customer.email_Conferma2,
          nomeCliente: this.userStore.userInfo!.payload.ragioneSociale,
          idCliente: this.userStore.userInfo!.payload.idCliente,
          idAzienda: this.userStore.currentIdAzienda || 1,
        });
      }

      if (
        this.rideNotifications.find((e) => e.destinatario == customer.email) ==
        undefined
      ) {
        this.rideNotifications.push({
          abilitato: true,
          destinatario: customer.email,
          nomeCliente: this.userStore.userInfo!.payload.ragioneSociale,
          idCliente: this.userStore.userInfo!.payload.idCliente,
          idAzienda: this.userStore.currentIdAzienda || 1,
        });
      }
    },
    async onAddRidePassenger(p: Passeggero) {
      const rideNotificationsToAdd: CentroNotifiche[] = [];
      const rideNotificationsEmails = this.rideNotifications.map(
        (e) => e.destinatario,
      );
      try {
        let slave: ClientiModel | null = null;
        if (p.iD_Cliente) {
          this.uiStore.toggleLoading(true);
          // it's a slave! Let's get the notification email from it's config
          slave = await this.userStore.customerApi.getCompanyCustomer(
            p.iD_Cliente,
            p.idAzienda || 1,
          );
          if (slave.email == p.email || slave.email_Conferma1 == p.email) {
            // when email is not changed then we use the confirm emails
            // otherwise we use the new email
            if (
              slave.email_Conferma1 &&
              !rideNotificationsEmails.includes(slave.email_Conferma1)
            ) {
              rideNotificationsToAdd.push({
                abilitato: true,
                destinatario: slave.email_Conferma1,
                nomeCliente: p.passeggero,
                idAzienda: p.idAzienda,
              });
            }
            if (
              slave.email_Conferma2 &&
              slave.email_Conferma2 != slave.email_Conferma1 &&
              !rideNotificationsEmails.includes(slave.email_Conferma2)
            ) {
              rideNotificationsToAdd.push({
                abilitato: true,
                destinatario: slave.email_Conferma2,
                nomeCliente: p.passeggero,
                idAzienda: p.idAzienda,
              });
            }
          }
        }
        if (
          rideNotificationsToAdd.length == 0 &&
          p.email &&
          !rideNotificationsEmails.includes(p.email)
        ) {
          rideNotificationsToAdd.push({
            abilitato: true,
            destinatario: p.email,
            nomeCliente: p.passeggero,
            idAzienda: p.idAzienda,
          });
        }
        this.rideNotifications.push(...rideNotificationsToAdd);
        return slave;
      } catch (e) {
        console.error("Cannot execute onAddRidePassenger!");
        if (e instanceof Error) {
          console.error(e.message);
          console.error(e.stack);
          console.error(e.cause);
          console.error(e.name);
        }
        this.toast.error(this.i18n.global.t("Error_server"));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },
    addNotificationReceiver() {
      this.rideNotifications.push({
        abilitato: true,
      });
    },
    async removeNotificationReceiver(notification: Partial<CentroNotifiche>) {
      this.rideNotifications = this.rideNotifications.filter(
        (e) => e.destinatario != notification.destinatario,
      );
      if (notification.id) {
        this.uiStore.toggleLoading(true);
        await this.userStore.customerApi.deleteNotificationEmail(
          notification as CentroNotifiche,
          notification.idAzienda ?? 1,
        );
        this.uiStore.toggleLoading(false);
      }
    },
    onUpsertRideNotification(email: string, name: string) {
      if (!email || !isValidEmail(email)) {
        return;
      }
      const found = this.rideNotifications.find((e) => e.destinatario == email);
      if (!found) {
        this.rideNotifications.push({
          destinatario: email,
          nomeCliente: name,
          idAzienda: this.userStore.currentIdAzienda,
          abilitato: true,
        });
        return;
      }
      found.nomeCliente = name;
    },
  },
});

// if (import.meta.hot) {
//   import.meta.hot.accept(acceptHMRUpdate(useTripStore, import.meta.hot)); // allows hot reloading of the store
// }

// export const useTripStore = () => {
//   const store = tripStoreFactory();

//   if (!store.tripToInsert) {
//     store.tripToInsert =
//       store.getLocalTrip() ?? ({ ...TRIP_TO_INSERT_INITIAL_VALUE } as Viaggio);
//   }

//   if (!store.preventivo) {
//     store.recoverPreventivoFromLocal();
//   }

//   if (!store.rideType) {
//     store.rideType = store.getLocalRideType();
//   }

//   store.$subscribe(() => {
//     store.saveLocally();
//   });

//   return store;
// };
